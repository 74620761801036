import Component from '../app/component.js'

export default class extends Component {
  constructor (el) {
    super(el)
    this.data = {
      country: null,
      countryLabelDefault: null,
      title: null
    }
  }

  init () {
    // country select
    if (this.refs.countrySelect) {
      this.refs.countrySelect.addEventListener('change', () => {
        this.data.country = this.refs.countrySelect.value
      })
      this.data.countryLabelDefault = this.refs.countryLabel.textContent.trim()
    }

    // search
    if (this.refs.searchInput) {
      this.refs.searchInput.addEventListener('keyup', () => {
        this.data.title = this.refs.searchInput.value
      })
    }

    // load more
    if (this.refs.loadMore) {
      this.refs.loadMore.addEventListener('click', () => {
        const url = this.refs.loadMore.getAttribute('data-url')
        if (url) {
          this.loadMore(url)
        }
      })
    }
  }

  watchCountry (value) {
    if (this.data.countryLabelDefault) {
      this.refs.countryLabel.textContent = value ? this.refs.countrySelect.querySelector(`option[value="${value}"]`).textContent : this.data.countryLabelDefault
    }
    if (value) {
      this.data.title = null // reset title
      this.filterByCountry(value)
    } else {
      this.filterByCountry(null)
    }
  }

  watchTitle (value) {
    this.refs.searchInput.value = value // update text input
    if (value) {
      this.data.country = null // reset country
      this.filterByTitle(value)
    }
  }

  filterByTitle (value) {
    if (value && value.length < 2) {
      value = '' // ignore filter
    }
    const regExp = new RegExp(`^${value}`, 'i')
    this.refs.items.forEach(el => {
      const title = el.querySelector('.cm-stores-title').textContent.trim()
      if (title.match(regExp) || value === null) {
        el.classList.remove('cm-stores-item--inactive')
      } else {
        el.classList.add('cm-stores-item--inactive')
      }
    })
  }

  filterByCountry (value) {
    this.refs.items.forEach(el => {
      const country = el.querySelector('.cm-stores-country').textContent.trim()
      if (country === value || value === null) {
        el.classList.remove('cm-stores-item--inactive')
      } else {
        el.classList.add('cm-stores-item--inactive')
      }
    })
  }

  loadMore (url) {
    fetch(url)
      .then(response => response.text())
      .then(html => {
        if (html) {
          const domParser = new DOMParser()
          const doc = domParser.parseFromString(html, 'text/html')
          const items = doc.querySelectorAll('.cm-stores-item')
          if (items) {
            items.forEach(el => this.refs.wrapper.appendChild(el))
            this.refs.items = Array.from(this.refs.wrapper.querySelectorAll('li'))
            this.data.title = null // reset filter
            this.data.country = null // reset filter
          }
          const loadMore = doc.querySelector('.cm-stores-loadmore a')
          if (loadMore) {
            this.refs.loadMore.setAttribute('data-url', loadMore.getAttribute('data-url'))
          } else {
            this.refs.loadMore.parentNode.removeChild(this.refs.loadMore)
          }
        }
      })
  }
}
