import Component from '../app/component.js'
import gsap from 'gsap'

export default class extends Component {
  constructor (el) {
    super(el)
    this.data = {
      display: false,
      displayIgnoreFirst: true,
      interactions: true
    }
  }

  init () {
    document.addEventListener('fsmenu.toggle', () => {
      if (this.data.interactions) {
        this.data.display = !this.data.display
      }
    })
  }

  watchDisplay (value) {
    if (this.data.displayIgnoreFirst) {
      this.data.displayIgnoreFirst = false
      return
    }

    this.data.interactions = false
    
    if (value) {
      document.dispatchEvent(new CustomEvent('top.menu.close'))
      this.el.classList.add('cm-fsmenu--active')
      document.body.classList.add('is-unscrollable')
      this.tweenIn().then(() => {
        this.data.interactions = true
      })
    } else {
      this.tweenOut().then(() => {
        document.dispatchEvent(new CustomEvent('top.menu.open'))
        this.el.classList.remove('cm-fsmenu--active')
        document.body.classList.remove('is-unscrollable')
        this.data.interactions = true
      })
    }
  }

  tweenIn () {
    const tl = gsap.timeline()

    if (this.refs.background) {
      tl.fromTo(
        this.refs.background,
        {
          scaleY: 0
        },
        {
          scaleY: 1,
          duration: 1,
          ease: 'quint.inOut',
          clearProps: 'all'
        },
        0
      )
    }

    if (this.refs.columns) {
      tl.fromTo(
        this.refs.columns,
        {
          opacity: 0,
          y: '1rem'
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.1,
          ease: 'circ.inOut',
          clearProps: 'all'
        },
        0.5
      )
    }

    return tl.then()
  }
  
  tweenOut () {
    const tl = gsap.timeline()

    if (this.refs.background) {
      tl.to(
        this.refs.background,
        {
          scaleY: 0,
          duration: 0.75,
          ease: 'quint.out'
        },
        0
      )
    }

    if (this.refs.columns) {
      tl.to(
        this.refs.columns,
        {
          opacity: 0,
          y: '-1rem',
          duration: 0.3,
          stagger: 0.1,
          ease: 'circ.out'
        },
        0
      )
    }

    return tl.then()
  }
}
