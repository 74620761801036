import Component from '../app/component.js'

export default class extends Component {
  constructor (el) {
    super(el)
    this.data = {}
  }

  init () {
    if (!this.refs?.images?.length) {
      return
    }

    lightGallery(this.el)
  }
}
