import Component from '../app/component.js'

export default class extends Component {
  constructor (el) {
    super(el)
    this.data = {
      type: null,
      typeLabelDefault: null
    }
  }

  init () {
    // load more
    if (this.refs.loadMore) {
      this.refs.loadMore.addEventListener('click', () => {
        const url = this.refs.loadMore.getAttribute('data-url')
        if (url) {
          this.loadMore(url)
        }
      })
    }

    // type select
    if (this.refs.typeSelect) {
      this.refs.typeSelect.addEventListener('change', () => {
        this.data.type = this.refs.typeSelect.value
      })
      this.data.typeLabelDefault = this.refs.typeLabel.textContent.trim()
    }
  }

  loadMore (url) {
    fetch(url)
      .then(response => response.text())
      .then(html => {
        if (html) {
          const domParser = new DOMParser()
          const doc = domParser.parseFromString(html, 'text/html')
          const items = doc.querySelectorAll('.cm-projects-project')
          if (items) {
            items.forEach(el => this.refs.wrapper.appendChild(el))
            this.refs.items = Array.from(this.refs.wrapper.querySelectorAll('li'))
            this.data.type = null // reset filter
          }
          const loadMore = doc.querySelector('.cm-projects-loadmore a')
          if (loadMore) {
            this.refs.loadMore.setAttribute('data-url', loadMore.getAttribute('data-url'))
          } else {
            this.refs.loadMore.parentNode.removeChild(this.refs.loadMore)
          }
        }
      })
  }

  watchType (value) {
    if (this.data.typeLabelDefault) {
      this.refs.typeLabel.textContent = value ? this.refs.typeSelect.querySelector(`option[value="${value}"]`).textContent : this.data.typeLabelDefault
    }

    this.refs.items.forEach(el => {
      if (el.getAttribute('data-types').split(', ').includes(value) || !value) {
        el.classList.remove('cm-projects-project--hidden')
      } else {
        el.classList.add('cm-projects-project--hidden')
      }
    })
  }
}
