import Component from '../app/component.js'
import gsap from 'gsap'

export default class extends Component {
  constructor (el) {
    super(el)
    this.data = {
      index: 0,
      indexMax: 0
    }
  }

  init () {
    this.data.indexMax = this.refs.slides.length - 1
  }

  watchIndex (value) {
    this.data.interactions = false
    
    Promise.resolve()
      .then(() => this.tweenToggleOut())
      .then(() => {
        this.refs.slides.forEach((el, i) => {
          el.classList[i === value ? 'add' : 'remove']('cm-heroslider-slide--active')
        })
      })
      .then(() => this.tweenToggleIn())
      .then(() => {
        this.data.interactions = true
      })

  }

  next () {
    if (!this.data.interactions) {
      return
    }
    this.data.index = (this.data.index + 1 > this.data.indexMax) ? 0 : this.data.index + 1
  }

  prev () {
    if (!this.data.interactions) {
      return
    }
    this.data.index = (this.data.index - 1 < 0) ? this.data.indexMax : this.data.index - 1
  }

  tweenToggleOut () {
    const tl = gsap.timeline()
    const slide = this.el.querySelector('.cm-heroslider-slide--active')

    if (!slide) {
      return Promise.resolve()
    }

    const overlay = slide.querySelector('.cm-heroslider-loaderoverlay')
    const image = slide.querySelector('.cm-heroslider-image')

    tl.to(
      overlay,
      {
        scaleX: 1,
        duration: 0.5,
        ease: 'power2.inOut',
        clearProps: 'all'
      },
      0
    )
    
    if (image) {
      tl.to(
        image,
        {
          scale: 1.1,
          duration: 0.5,
          ease: 'power2.inOut',
          clearProps: 'all'
        },
        0
      )
    }

    return tl.then()
  }

  tweenToggleIn () {
    const tl = gsap.timeline()
    const slide = this.refs.slides[this.data.index]

    if (!slide) {
      return Promise.resolve()
    }

    const overlay = slide.querySelector('.cm-heroslider-loaderoverlay')
    const image = slide.querySelector('.cm-heroslider-image')

    overlay.style.transformOrigin = '100% 50%'

    tl.fromTo(
      overlay,
      {
        scaleX: 1
      },
      {
        scaleX: 0,
        duration: 0.75,
        ease: 'power2.out',
        clearProps: 'all'
      },
      0
    )

    if (image) {
      tl.fromTo(
        image,
        {
          scale: 1.1,
        },
        {
          scale: 1,
          duration: 0.5,
          ease: 'power2.out',
          clearProps: 'all'
        },
        0
      )
    }

    return tl.then()
  }
}
