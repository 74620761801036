import Component from '../app/component.js'
import Glide from '@glidejs/glide'

export default class extends Component {
  constructor (el) {
    super(el)
    this.data = {
      index: 0
    }
  }

  init () {
    this.slider = new Glide(this.refs.slider, {
      type: 'carousel',
      autoplay: 5000
    })

    this.slider.on('run', () => {
      this.data.index = this.slider.index
    })
    this.slider.mount()

    this.refs.indexes.forEach(el => {
      el.addEventListener('click', () => {
        if (this.slider) {
          this.slider.go(`=${this.refs.indexes.indexOf(el)}`)
        }
      })
    })

    if (this.refs.prev) {
      this.refs.prev.addEventListener('click', () => {
        this.slider.go('<')
      })
    }
    if (this.refs.next) {
      this.refs.next.addEventListener('click', () => {
        this.slider.go('>')
      })
    }

    this.setupLightbox()
  }

  setupLightbox () {
    if (!this.refs.lightbox) {
      return
    }

    const images = []
    this.refs.slides.forEach(el => {
      images.push({
        src: el.getAttribute('data-src'),
        thumb: el.getAttribute('data-thumb')
      })
    })

    this.refs.lightbox.addEventListener('click', () => {
      lightGallery(
        this.refs.lightbox,
        {
          dynamic: true,
          dynamicEl: images,
          index: this.data.index
        }
      )
    })
  }

  watchIndex (value) {
    this.refs.indexes.forEach((el, i) => {
      if (i === value) {
        el.classList.add('cm-productslider-index--active')
      } else {
        el.classList.remove('cm-productslider-index--active')
      }
    })
  }
}
