import Component from '../app/component.js'

export default class extends Component {
  init () {
    if (this.refs.menu) {
      this.refs.menu.addEventListener('click', () => {
        document.dispatchEvent(new CustomEvent('fsmenu.toggle'))
      })

      document.addEventListener('top.menu.close', () => {
        this.el.classList.add('cm-top--menuclose')
      })
      document.addEventListener('top.menu.open', () => {
        this.el.classList.remove('cm-top--menuclose')
      })
    }

    this.intersectionLineSetup()
  }

  intersectionLineSetup () {
    // add intersection line
    const intLineEl = document.createElement('div')
    intLineEl.classList.add('ui-intersection-line')
    document.body.appendChild(intLineEl)

    const intListener = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        this.el.classList.remove('cm-top--scroll')
      } else {
        this.el.classList.add('cm-top--scroll')
      }
    })
    intListener.observe(intLineEl)
  }
}
