import app from './app/app.js'

import mediaQueries from './objects/media-queries.js'
import vh from './objects/vh.js'

import FsMenu from './components/fsmenu.js'
import HeroSlider from './components/heroslider.js'
import Images from './components/images.js'
import News from './components/news.js'
import ProductSlider from './components/productslider.js'
import Projects from './components/projects.js'
import Stores from './components/stores.js'
import Top from './components/top.js'

import 'lightgallery.js' // to global scope

app
  .addComponent('fsmenu', FsMenu)
  .addComponent('heroslider', HeroSlider)
  .addComponent('images', Images)
  .addComponent('news', News)
  .addComponent('productslider', ProductSlider)
  .addComponent('projects', Projects)
  .addComponent('stores', Stores)
  .addComponent('top', Top)
  .init()

// media queries
mediaQueries.add('small', '(max-width: 720px)')
mediaQueries.add('medium', '(min-width: 721px) and (max-width: 1024px)')
mediaQueries.add('large', '(min-width: 1025px) and (max-width: 1640px)')
mediaQueries.add('wide', '(min-width: 1641px)')

// vh
vh.init()
