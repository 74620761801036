import Component from '../app/component.js'

export default class extends Component {
  constructor (el) {
    super(el)
    this.data = {}
  }

  init () {
    // load more
    if (this.refs.loadMore) {
      this.refs.loadMore.addEventListener('click', () => {
        const url = this.refs.loadMore.getAttribute('data-url')
        if (url) {
          this.loadMore(url)
        }
      })
    }
  }

  loadMore (url) {
    fetch(url)
      .then(response => response.text())
      .then(html => {
        if (html) {
          const domParser = new DOMParser()
          const doc = domParser.parseFromString(html, 'text/html')
          const items = doc.querySelectorAll('.cm-news-item')
          if (items) {
            items.forEach(el => this.refs.wrapper.appendChild(el))
            this.refs.items = Array.from(this.refs.wrapper.querySelectorAll('li'))
          }
          const loadMore = doc.querySelector('.cm-news-loadmore a')
          if (loadMore) {
            this.refs.loadMore.setAttribute('data-url', loadMore.getAttribute('data-url'))
          } else {
            this.refs.loadMore.parentNode.removeChild(this.refs.loadMore)
          }
        }
      })
  }
}
