import utils from '../app/utils'

export default {
  value: 0,

  init () {
    this.setValue()
    window.addEventListener('resize', utils.debounce(() => this.setValue(), 200))
  },

  setValue () {
    this.value = window.innerHeight * 0.01
    window.document.documentElement.style.setProperty('--vh', `${this.value}px`)
  }
}
